@import 'assets/scss/font.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/buttons.scss';
@import 'assets/scss/override.scss';


html, body {
    @include font-regular();
    margin: 0 !important;
    font-size: 15px;
}


// ::-webkit-scrollbar {
//     width: 0em;
// }
  
//   ::-webkit-scrollbar-thumb {
//     background: rgba(178, 184, 227, 0.1);
//     border-radius: var(--curved-radius);
//   }
  
::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #dad9d9; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(196, 196, 197);
    border-radius: var(--curved-radius);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(178, 184, 227, 0.1);
    border-radius: var(--curved-radius);
  }

h1, h2, h3, h4, h5, h6 {
    @include font-sf-pro-regular();
    font-weight: 600;

}

.w-100 {
    width: 100% !important;
}

.w-90 {
    width: 90% !important;
}

.w-95 {
    width: 95% !important;
}

.w-85 {
    width: 85% !important;
}


.w-80 {
    width: 80% !important;
}

.w-70 {
    width: 70% !important;
}

.w-50 {
    width: 50% !important;
}


.w-40 {
    width: 40% !important;
}

.w-35 {
    width: 35% !important;
}

.w-30 {
    width: 30% !important;
}

.w-20 {
    width: 20% !important;
}

.w-15 {
    width: 15% !important;
}
  
.v-100 {
    min-height: 100vh !important;
}

.v-80 {
    min-height: 80vh !important;
}

.v-80-force {
    height: 80vh !important;
}

.v-50 {
    min-height: 50vh !important;
}

.v-50-force {
    height: 50vh !important;
}

.v-60 {
    min-height: 60vh !important;
}

.v-60-force {
    height: 60vh !important;
}

.v-100-force {
    height: 500px !important;
}
  
.form {
    width: 350px;
}
  
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(5px);
    overflow:hidden;
}
  
.v-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 999;
  
    @media screen and (max-height: 400px) and (orientation: landscape) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      -ms-transform: none;
    }
}
  
.shadow {
    box-shadow: 0 15px 25px -5px rgba(0,0,0,0.08);
    -webkit-box-shadow: 0 15px 25px -5px rgba(0,0,0,0.08);
    -moz-box-shadow: 0 15px 25px -5px rgba(0,0,0,0.08);
}

.info-panel-shadow {
    box-shadow: 0px 5px 10px #00000029;
}
  
.rounded-corner {
    border-radius: var(--curved-radius) !important;
}
  
.text-bold {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700 !important;
}
  
.text-thin {
    @include font-regular();
}
  
.text-uppercase {
    text-transform: uppercase;
}
  
.text-ellipsis {
    @include ellipsis();
}
  
// .page-title-primary {
//     @include font-bold();
//     font-size: 2.5rem;
//     margin-right: .5rem;
  
//     @media screen and (orientation: portrait) and (max-width: 768px) {
//       font-size: 1.75rem;
//     }
// }


.page-title-primary {
    @include font-bold();
    font-size: 1.3rem;
  
    @media screen and (orientation: portrait) and (max-width: 768px) {
      font-size: 0.8rem;
    }
}


  
.page-caption {
    margin-top: -1.5rem;
}
  
.page-container {
    // min-height: 80vh;
    height: 100%;
    overflow-y: auto;
}
  
.overflow-set {
    overflow-y: auto;
}

.flex-basis-50 {
    flex-basis: 50%;
  
    @media screen and (max-width: 768px) {
      flex-basis: 100%;
    }
}
  
.col-actions {
    width: 5em;
}
  
img {
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
}
  
.account-avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
    object-fit: cover;
}

.id-avatar {
    height: 80px !important;
    width: 80px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
    object-fit: contain;
}


.card-banner {
    background: var(--off-white);
    min-height: 190px;
    max-height: 190px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
  
    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
     object-position: center;
    }
}
  
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  
input[type=number] {
  -moz-appearance: textfield;
}
  
.error-text {
    font-size: 15px;
    margin-top: 2px;
    color: red;
    margin-bottom: 2px;
    text-align: left;
}

.days-SUN {
    background: #ec5f72 !important;
}

.days-MON {
    background: #72c1fa !important;

}

.days-TUE {
    background: #f0945f !important;
}

.days-WED {
    background: #88fce5 !important;
}

.days-THU {
    background: #72c1fa !important;

}

.days-FRI {
    background: #586df6 !important;
}

.days-SAT {
    background: #fae56d !important;
}

.active-contract-tag {
    background: #23CB9D !important;
    color: white !important;
}

.text-error {
    color: var(--velvet);
}

p-calendar.calendar-shift{
	display: grid;
	box-shadow: 0px 4px 12px #00000029;
	.p-datepicker-title{
		span{
			font-size:14px;
		}
	}
	thead{
		th{
			span{
				font-size:14px;	
			}
		}
	}
	table{
		td.p-datepicker-today{
			> span{
				background: transparent;
			}
		}
		td>span{
			&:focus{
				box-shadow: none;
			}
		}	
		td>span>span{
			font-family: var(--RR);
			font-size: 13px;
			color: var(--opentime-color-secondary-light);
			width: 2.5rem;
    		height: 2.5rem;
    		border-radius: 50%;
			align-items: center;
			justify-content: center;
			display: flex;
		}
		td>span.p-disabled>span{
			background: var(--opentime-color-unavailable-box);
			color: var(--opentime-color-secondary-light);
		}
		td> span.p-highlight > span{
			background: var(--opentime-color-primary) !important;
			color: var(--opentime-color-primary-light);
		}
		td>span>span.date-enabled{
			// background: var(--opentime-color-opentimer-primary);
			// color: var(--opentime-color-primary-light);
			&:after{
				content: '';
				height: 4px;
				width: 4px;
				border-radius: 50%;
				background: var(--success);
				position: absolute;
				bottom: 6px;
				left: 50%;
				transform: translateX(-50%);
                top: 16px;
			}
		}
	}
}