@font-face {
    font-family: "HelveticaBold";
    src: url("../fonts/HelveticaBold/HelveticaBold.eot");
    src: url("../fonts/HelveticaBold/HelveticaBold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/HelveticaBold/HelveticaBold.woff2") format("woff2"),
        url("../fonts/HelveticaBold/HelveticaBold.woff") format("woff"),
        url("../fonts/HelveticaBold/HelveticaBold.ttf")
            format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaRoman";
    src: url("../fonts/HelveticaNeue-Roman/HelveticaNeue-Roman.eot");
    src: url("../fonts/HelveticaNeue-Roman/HelveticaNeue-Roman.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/HelveticaNeue-Roman/HelveticaNeue-Roman.woff2")
            format("woff2"),
        url("../fonts/HelveticaNeue-Roman/HelveticaNeue-Roman.woff")
            format("woff"),
        url("../fonts/HelveticaNeue-Roman/HelveticaNeue-Roman.ttf")
            format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaMedium";
    src: url("../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.eot");
    src: url("../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.woff2")
            format("woff2"),
        url("../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.woff")
            format("woff"),
        url("../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.ttf")
            format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaRegular";
    src:url("../fonts/HelveticaNeueRegular/Helvetica.ttc")
            format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-Medium";
    src: url("../fonts/Roboto-Medium/Roboto-Medium.eot");
    src: url("../fonts/Roboto-Medium/Roboto-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/Roboto-Medium/Roboto-Medium.woff2") format("woff2"),
        url("../fonts/Roboto-Medium/Roboto-Medium.woff") format("woff"),
        url("../fonts/Roboto-Medium/Roboto-Medium.ttf")
            format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProText-Semibold';
    src: url('../fonts/SF-Pro-Text-SemiBold/FontsFree-Net-SFProText-Semibold.eot');
    src: url('../fonts/SF-Pro-Text-SemiBold/FontsFree-Net-SFProText-Semibold.woff') format('woff'),
        url('../fonts/SF-Pro-Text-SemiBold/FontsFree-Net-SFProText-Semibold.ttf') format('truetype'),
        url('../fonts/SF-Pro-Text-SemiBold/FontsFree-Net-SFProText-Semibold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText-Regular';
    src: url('../fonts/SF-Pro-Text-Regular/FontsFree-Net-SFProText-Regular.eot');
    src:  url('../fonts/SF-Pro-Text-Regular/FontsFree-Net-SFProText-Regular.woff')  format('woff'),
        url('../fonts/SF-Pro-Text-Regular/FontsFree-Net-SFProText-Regular.ttf') format('truetype'),
        url('../fonts/SF-Pro-Text-Regular/FontsFree-Net-SFProText-Regular.svg') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("../fonts/Roboto-Bold/Roboto-Bold.eot");
    src: url("../fonts/Roboto-Bold/Roboto-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/Roboto-Bold/Roboto-Bold.woff2") format("woff2"),
        url("../fonts/Roboto-Bold/Roboto-Bold.woff") format("woff"),
        url("../fonts/Roboto-Bold/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("../fonts/Roboto-Regular/Roboto-Regular.eot");
    src: url("../fonts/Roboto-Regular/Roboto-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/Roboto-Regular/Roboto-Regular.woff2")
            format("woff2"),
        url("../fonts/Roboto-Regular/Roboto-Regular.woff") format("woff"),
        url("../fonts/Roboto-Regular/Roboto-Regular.ttf")
            format("truetype");
}


@font-face {
    font-family: "SFProText-Regular";
    src: url("../fonts/SFProText-Regular/SFProText-Regular.eot");
    src: url("../fonts/SFProText-Regular/SFProText-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/SFProText-Regular/SFProText-Regular.woff2")
            format("woff2"),
        url("../fonts/SFProText-Regular/SFProText-Regular.woff")
            format("woff"),
        url("../fonts/SFProText-Regular/SFProText-Regular.ttf")
            format("truetype");
}

@font-face {
    font-family: "SFProText-Regular";
    src: url("../fonts/SFProText-Regular/SFProText-Regular.eot");
    src: url("../fonts/SFProText-Regular/SFProText-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/SFProText-Regular/SFProText-Regular.woff2")
            format("woff2"),
        url("../fonts/SFProText-Regular/SFProText-Regular.woff")
            format("woff"),
        url("../fonts/SFProText-Regular/SFProText-Regular.ttf")
            format("truetype");
}

@font-face {
    font-family: "HelveticaRounded-Bold";
    src: url("../fonts/HelveticaRounded-Bold/HelveticaRounded-Bold.eot");
    src: url("../fonts/HelveticaRounded-Bold/HelveticaRounded-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/HelveticaRounded-Bold/HelveticaRounded-Bold.woff2")
            format("woff2"),
        url("../fonts/HelveticaRounded-Bold/HelveticaRounded-Bold.woff")
            format("woff");
}

@font-face {
    font-family: "SFProRounded-Bold";
    src: url("../fonts/SFProRounded-Bold/SFProRounded-Bold.eot");
    src: url("../fonts/SFProRounded-Bold/SFProRounded-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/SFProRounded-Bold/SFProRounded-Bold.woff2")
            format("woff2"),
        url("../fonts/SFProRounded-Bold/SFProRounded-Bold.woff")
            format("woff"),
        url("../fonts/SFProRounded-Bold/SFProRounded-Bold.ttf")
            format("truetype"),
        url("../fonts/SFProRounded-Bold/SFProRounded-Bold.otf")
            format("opentype");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family: 'Righteous-Regular';
    src: url('../fonts/Righteous-Regular/Righteous-Regular.eot');
    src: url('../fonts/Righteous-Regular/Righteous-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Righteous-Regular/Righteous-Regular.woff2') format('woff2'),
        url('../fonts/Righteous-Regular/Righteous-Regular.woff') format('woff'),
        url('../fonts/Righteous-Regular/Righteous-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RighteousRegular";
    src:url("../fonts/Righteous-Regular.ttf")
            format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

:root {
    // variables font family
    --HR: "HelveticaRegular";
    --HB: "HelveticaBold";
    --HRR: "HelveticaRoman";
    --HM: "HelveticaMedium";
    --RM: "Roboto-Medium";
    --RB: "Roboto-Bold";
    --RR: "Roboto-Regular";
    --SR: "SFProText-Regular";
    --SPR: "SFProText-Regular";
    --SSB: "SFProText-SemiBold";
    --HRB: "HelveticaRounded-Bold";
    --SRB: "SFProRounded-Bold";
    --RTR: "Righteous-Regular";
    --RT: "RighteousRegular";
}

.text-ssb {
    font-family: var(--SSB);
}

.text-rm {
    font-family: var(--RM);
}

.text-rb {
    font-family: var(--RB) !important;
}