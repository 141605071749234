@import 'font.scss';
@import 'pallete.scss';
@import 'mixins.scss';
@import 'node_modules/css-star-rating/scss/star-rating.scss';


.rating.color-positive .star-container .star {
    svg {
      fill: var(--primary) !important;
    }
    i {
      color: var(--primary) !important;
    }
}

.rating.color-ok .star-container .star {
  svg {
    fill: var(--success) !important;
  }
  i {
    color: var(--success) !important;
  }
}


// ::-webkit-scrollbar {
//   width: 0em !important;
// }

p {
    margin: 2px !important;
    padding: 2px !important;
}

::ng-deep .p-component,
::ng-deep .p-element:not(.pi){
  @include font-sf-pro-regular();
}

::ng-deep .p-focus {
  outline: none;
  outline-offset: unset !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

::ng-deep .p-button {
  color: white;
  border: 0;
  height: 40px;
}

.button-border-none {
  border: none !important;
}

.p-button-link-no-padding {
  .p-button  {
    color: white;
    border: 0;
    height: 35px !important;
    text-align: left;
    padding: 0.10rem;
  }
}

::ng-deep .velvet {
  .p-button-link{
    color: red !important
  }
}

::ng-deep .p-avatar img {
  @include image-crop();
}

::ng-deep .p-input-filled .p-inputtext {
    background-color: #EFEFEF !important;
}

::ng-deep .p-float-label {
    margin-top: 2em;
}

::ng-deep .p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da !important;
}
  
::ng-deep .p-inputtext{
    @include font-sf-pro-regular();
    border-radius: var(--curved-radius);
    height: 40px !important;
    border-top: solid thin rgba(0, 0, 0, .5);
    border-right: solid thin rgba(0, 0, 0, .5);
    border-left: solid thin rgba(0, 0, 0, .5);
    border-bottom:solid thin rgba(0, 0, 0, .5);
}

::ng-deep .p-chips{
  @include font-sf-pro-regular();
  border-radius: var(--curved-radius);
  // height: 40px !important;
  border-top: solid thin rgba(0, 0, 0, .5);
  border-right: solid thin rgba(0, 0, 0, .5);
  border-left: solid thin rgba(0, 0, 0, .5);
  border-bottom:solid thin rgba(0, 0, 0, .5);
  .p-inputtext{
    border: none !important;
  }
}

::ng-deep .p-dropdown,
::ng-deep .p-dropdown:not(.p-disabled).p-focus{
  @include font-sf-pro-regular();
  border-radius: var(--curved-radius);
  height: 40px !important;
  border-top: solid thin rgba(0, 0, 0, .5) !important;
  border-right: solid thin rgba(0, 0, 0, .5) !important;
  border-left: solid thin rgba(0, 0, 0, .5) !important;
  border-bottom:solid thin rgba(0, 0, 0, .5) !important; 
}

::ng-deep .p-multiselect,
::ng-deep .p-multiselect:not(.p-disabled).p-focus{
  @include font-sf-pro-regular();
  border-radius: var(--curved-radius);
  height: 40px !important;
  border-top: solid thin rgba(0, 0, 0, .5) !important;
  border-right: solid thin rgba(0, 0, 0, .5) !important;
  border-left: solid thin rgba(0, 0, 0, .5) !important;
  border-bottom:solid thin rgba(0, 0, 0, .5) !important; 
}
  
::ng-deep .p-inputtext:disabled {
    background: #fafafa;
}
  
::ng-deep .p-inputtext:enabled:hover {
    border-bottom: solid thin var(--primary-hover) !important;
}

::ng-deep .p-datatable .p-datatable-header  {
  padding: 1rem 0rem 1rem 0rem !important;
}

::ng-deep .p-datepicker table td {
  // padding: 1.5rem !important;
}



::ng-deep .p-datatable .p-datatable-thead > tr > th  {
  padding: 0.5rem 0.5rem !important;
  font-size: 13.5px !important;
}


::ng-deep .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem !important;
  font-size: 14px !important;

  p {
    font-size: 14px !important;
  }
}

::ng-deep .p-datepicker table td > span { 
  height: 10px !important;
  width: 10px !important;
  padding: 10px !important;
}


::ng-deep  .p-chip {
  font-size: 12px;
}

.opentime-card-panel {
  width: 280px !important;
  height: 115px !important;
  margin: 0.30rem;
  padding: 0.25rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px #00000029;

  cursor: pointer;

  .p-card-body {
    padding: 0 !important;
    width: 100%;
  }
}

::ng-deep .p-card {
  border-radius: 5px !important;
  box-shadow: 0px 5px 10px #00000029 !important;
}

label {
  font-family: var(--RR);   
  font-size: 14px;
  padding-top: 0.8rem !important;
}

//AUTOCOMPLETE
.autocomplete-input {
  width: 100% !important;
  i.ui-autocomplete-loader {
    margin: -44px;
  }
}


.edit-dialog {
  min-height: 20vw;
}



.timezone-checkbox {

  .p-checkbox-box {
    border-radius: 15px !important;
  }
}


.jobtype-checkbox {
  .p-checkbox-box {
    border-radius: 15px !important;
    height: 40px !important;
    width: 40px !important;
  }
}

.timezone-tag.p-tag {
    background: var(--off-white);
    color: black;
}


.skill-level-menu {
  display: none !important;
}


.job-skill-experience-button {
  .p-button {
    background-color: #E2E2E2;
    color: #000;

    &:hover:enabled,
    &:focus:enabled {
      background: #e0dfdf !important;
    }
  }
}

.beginner-level {
  .p-button {
    background-color: #4A69AD !important;
    color: white;

    &:hover:enabled,
    &:focus:enabled {
      background: #7d98d3 !important;
    }
  }

  .p-chip {
    background-color: #4A69AD !important;
    color: white;
    font-weight: bold;
  }
}

.intermediate-level {
  .p-button {
    background-color: #79BF3D !important;
    color: white;

    &:hover:enabled,
    &:focus:enabled {
      background: #b0e581 !important;
      color: white;
      font-weight: bold;
    }
  }

  .p-chip {
    background-color: #79BF3D !important;
    color: white;
    font-weight: bold;
  }
}

.advanced-level {
  .p-button {
    background-color: #DB940D !important;
    color: white;

    &:hover:enabled,
    &:focus:enabled {
      background: #f1bc5b !important;
    }
  }

  .p-chip {
    background-color: #DB940D !important;
    color: white;
    font-weight: bold;
  }
}


.expert-level {
  .p-button {
    background-color: #A211B4 !important;
    color: white;

    &:hover:enabled,
    &:focus:enabled {
      background: #d85be9 !important;
    }
  }

  .p-chip {
    background-color: #A211B4 !important;
    color: white;
    font-weight: bold;
  }
}


.master-level {
  .p-button {
    background-color: #ED5555 !important;
    color: white;

    &:hover:enabled,
    &:focus:enabled {
      background: #f07070 !important;
    }
  }

  .p-chip {
    background-color: #ED5555 !important;
    color: white;
    font-weight: bold;
  }
}

.book-interview-calendar.p-calendar {
  width: -webkit-fill-available;
  box-shadow: 0px 3px 25px #00000029;
  border: 1px solid #D0D0D0;
  border-radius: 14px;
  table{
    td.p-datepicker-today{
      > span.p-disabled{
        background: var(--velvet);
        color: white;
      }
    }
    td{
      padding-top: 3px;
      > span.p-highlight{
        background: var(--primary);
        color: white;
      }
      > span.p-disabled{
        background: var(--off-white);
        color: var(--opentime-color-secondary-light);
      }
    }
    td>span{
      font-family: var(--RB);
      font-size: 14px;
      color: black;
      &:focus{
        box-shadow: none;
      }
    }
  }
  .p-datepicker-title{
    span{
      font-size:14px;
    }
  }
  thead{
    th{
      span{
        font-size:14px;	
      }
    }
  }
}

.activate-deactivate-modal {
  .p-dialog {
    border-radius: 10px;
    box-shadow: 0 15px 25px -5px rgba(0,0,0,0.08);

    // .p-dialog-header {
    //   padding: 0 !important;
    // }

    // .p-dialog-content {
    //   padding: 0 !important;
    // }
  }
}

.opentime-input-group {
  .p-inputgroup-addon {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    height: 40px !important;
  }
  
  input {
    border-top-left-radius:  0 !important;
    border-bottom-left-radius:  0 !important;
  }
}

.full-width-calendar-input {
  .p-calendar {
    width: 100%;
  }

  .p-calendar .p-datepicker {
    min-width: 50%;
  }
}

.booking-detail-dialog {
  .p-dialog-content {
    overflow: unset !important;
  }
}


// :ng-deep .p-input-full .p-inputnumber-input {
//   width: 200px;
// }

.p-inputnumber-input {
  width: 200px !important;
}


@media screen and (min-width: 360px) {
  .xs\:col {
    flex-grow: 1;
    flex-basis: 0 !important;
    padding: 0.5rem !important;
  }
  .xs\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .xs\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .xs\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .xs\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .xs\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .xs\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .xs\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .xs\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .xs\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .xs\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .xs\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .xs\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .xs\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
//AUTOCOMPLETE
//   ::ng-deep .p-inputtext:enabled:hover,
//   ::ng-deep .p-inputtext:enabled:focus {
//     border-top: none;
//     border-right: none;
//     border-left: none;
//     border-bottom: solid thin var(--primary);
//   }

::ng-deep .p-selectbutton .p-button.p-highlight.days-SUN {
  background: red !important;
  border-color: red !important;
  color: #ffffff;
}

// ::ng-deep .p-datatable table  {
//   width: 100% !important;
// }


ngx-dropzone {
  height: 250px !important;
  width: 250px !important;
  input {
      position: unset !important;
  }

 
}

::ng-deep  ngx-dropzone-image-preview {
  height: 100% !important;
  width: 100% !important;
  justify-content: start !important;
  img {
    height: 100% !important;
    width: 100% !important;
    padding: 0.25rem !important;
    object-fit: contain;
  }

}
